import actions from './actions';
import mutations from './mutations';

export default {
  namespaced: true,
  state: {
    rtspChannel: null,
    queue: [],
    taskCount: 0
  },
  actions,
  mutations
};
